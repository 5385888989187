<header class="header">
  <mat-toolbar class="header__content section--slant">
    <div class="content flex flex-row items-center space-x-1">
      <a routerLink="/" class="header__logo">
        <picture>
          <source
            type="image/webp"
            srcset="assets/img/wise-web-header-img.webp, assets/img/wise-web-header-img@2x.webp 2x"
          />
          <source
            srcset="assets/img/wise-web-header-img.png, assets/img/wise-web-header-img@2x.png 2x"
          />
          <img src="assets/img/wise-web-header-img@2x.png" i18n-alt alt="WISE header logo" />
        </picture>
      </a>
      <span class="flex-1"></span>
      <app-header-links
        class="hidden lg:block"
        [user]="user"
        [location]="location"
      ></app-header-links>
      @if (roles.length > 0) {
        <app-header-account-menu [user]="user"></app-header-account-menu>
      }
      @if (!location) {
        <span class="lg:hidden">
          <button mat-icon-button (click)="showMobileMenu()" i18n-aria-label aria-label="Main menu">
            <mat-icon>menu</mat-icon>
          </button>
        </span>
      }
    </div>
  </mat-toolbar>
</header>
