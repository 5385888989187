<div class="announcement mat-caption dark-theme center primary-bg flex items-center justify-center">
  <span class="announcement__content flex items-center justify-center gap-2">
    <span>{{ announcement.bannerText }}</span>
    @if (announcement.bannerButton) {
      <button mat-button color="primary" (click)="showAnnouncementDetails()">
        {{ announcement.bannerButton }}
      </button>
    }
  </span>
  <button
    class="announcement__dismiss"
    mat-icon-button
    i18n-aria-label
    aria-label="Dismiss"
    (click)="dismiss.emit()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
