<footer class="footer primary-bg dark-theme mat-caption">
  <div class="content secondary-text">
    <div class="flex flex-col md:flex-row flex-wrap justify-center items-center">
      <div class="flex flex-col sm:flex-row flex-wrap justify-center items-center">
        <div class="footer__section center">
          <a routerLink="/join" i18n>Create Account</a> |
          <a routerLink="/features" i18n>Features</a> | <a routerLink="/about" i18n>About Us</a> |
          <a href="https://wise-discuss.berkeley.edu/" i18n>Community</a>
        </div>
        <span class="hidden sm:block">/</span>
        <div class="footer__section center">
          <a routerLink="/help" i18n>Help + FAQs</a> |
          <a routerLink="/privacy" i18n>Privacy + Use</a> |
          <a routerLink="/contact" i18n>Contact Us</a>
        </div>
      </div>
      <span class="flex-1 hidden md:block"></span>
      <div class="flex flex-row">
        <a
          href="https://www.facebook.com/wisetels/"
          target="_blank"
          mat-icon-button
          i18n-aria-label
          aria-label="WISE on Facebook"
        >
          <mat-icon svgIcon="facebook-ffffff"></mat-icon>
        </a>
        <a
          href="https://twitter.com/WISETELS"
          target="_blank"
          mat-icon-button
          i18n-aria-label
          aria-label="WISE on Twitter"
        >
          <mat-icon svgIcon="twitter-ffffff"></mat-icon>
        </a>
        <a
          href="https://github.com/WISE-Community"
          target="_blank"
          mat-icon-button
          i18n-aria-label
          aria-label="WISE on Github"
        >
          <mat-icon svgIcon="github-ffffff"></mat-icon>
        </a>
      </div>
    </div>
    <div class="flex flex-col md:flex-row justify-center items-center">
      <div
        class="footer__section w-full md:flex-grow md:flex-shrink md:flex-0 place-items-center md:place-items-start"
      >
        <p i18n>
          Powered by
          <a href="https://wise-research.berkeley.edu" target="_blank">TELS Research</a> and
          <a href="https://github.com/WISE-Community" target="_blank">WISE Open Source Technology</a
          >. Help us <a href="https://crowdin.com/project/wise" target="_blank">translate</a> WISE!
        </p>
        <p i18n>
          This material is based upon work supported by the National Science Foundation. Any
          opinions, findings, and conclusions or recommendations expressed in this material are
          those of the author(s) and do not necessarily reflect the views of the National Science
          Foundation.
        </p>
      </div>
      <div class="w-full md:flex-grow md:flex-shrink md:flex-0">
        <div
          class="footer__section flex flex-row flex-wrap justify-center md:justify-end items-center"
        >
          <a href="https://www.nsf.gov/" target="_blank">
            <picture>
              <source
                type="image/webp"
                srcset="assets/img/footer/nsf-logo.webp, assets/img/footer/nsf-logo@2x.webp 2x"
              />
              <source
                srcset="assets/img/footer/nsf-logo.png, assets/img/footer/nsf-logo@2x.png 2x"
              />
              <img
                src="assets/img/footer/nsf-logo.png"
                i18n-alt
                alt="National Science Foundation"
              />
            </picture>
          </a>
          <a href="https://berkeley.edu" target="_blank">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets/img/footer/uc-berkeley-logo.webp,
                  assets/img/footer/uc-berkeley-logo@2x.webp 2x
                "
              />
              <source
                srcset="
                  assets/img/footer/uc-berkeley-logo.png,
                  assets/img/footer/uc-berkeley-logo@2x.png 2x
                "
              />
              <img src="assets/img/footer/uc-berkeley-logo.png" i18n-alt alt="UC Berkeley" />
            </picture>
          </a>
          <a href="https://ets.org" target="_blank">
            <img src="assets/img/footer/ETS-Logo-navy-gold.svg" i18n-alt alt="ETS" />
          </a>
          <a href="https://concord.org" target="_blank">
            <picture>
              <source
                type="image/webp"
                srcset="assets/img/footer/cc-logo.webp, assets/img/footer/cc-logo@2x.webp 2x"
              />
              <source srcset="assets/img/footer/cc-logo.png, assets/img/footer/cc-logo@2x.png 2x" />
              <img src="assets/img/footer/cc-logo.png" i18n-alt alt="Concord Consortium" />
            </picture>
          </a>
          <picture>
            <source
              type="image/webp"
              srcset="assets/img/footer/sail-logo.webp, assets/img/footer/sail-logo@2x.webp 2x"
            />
            <source
              srcset="assets/img/footer/sail-logo.png, assets/img/footer/sail-logo@2x.png 2x"
            />
            <img
              src="assets/img/footer/sail-logo.png"
              i18n-alt
              alt="SAIL (Scalable Architecture for Interactive Learning)"
            />
          </picture>
        </div>
        <div class="footer__section flex justify-center md:justify-end items-center">
          <span class="text-center md:text-end" i18n
            >©1996-{{ time | date: 'y' }},
            <a href="https://www.berkeley.edu" target="_blank">UC Berkeley</a> | Web-based Inquiry
            Science Environment</span
          >
        </div>
      </div>
    </div>
  </div>
</footer>
